import './App.css';
import routes from "./routes/Routes.js";
import { HashRouter, Route, Routes} from 'react-router-dom';
import theme from './theme';
import { ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { ToastContainer } from 'react-toast';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{height:"100%"}}>
      <HashRouter>
          <Routes>
            {routes.map(({ path, component, exact }) => (
              <Route key={path} path={path} element={component} exact={exact} />
            ))}
          </Routes>
      </HashRouter>
    </Box>
    <ToastContainer delay={4000}  position="bottom-right"/>
  </ThemeProvider>
  );
}

export default App;
