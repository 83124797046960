import './CardDustReward.css';
import { Box } from '@mui/material';
const CardDustReward = ({ img, tier, dust, url }) => {
  const handleClick = () => {
    if (url) {
      window.open(url);
    }
  };
  return (
    <>
      <div className="reward-card-container" onClick={handleClick}>
        <img src={img} />
        <div className="reward-card-description">
          <div>
            <p style={{margin:"0"}}>Card Tier</p>
            <h4>{tier}</h4>
          </div>
          <div style={{display:"flex",alignItems:"center"}}>
          <Box sx={{textAlign:"right"}}>
            <button className="dust-pool-btn">Buy {tier}</button>
          </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDustReward;
