import { useEffect, useRef } from 'react';
import './DistributionChart.css';
import pieChart from '../../assets/images/dust-page/pieChart.png';
import chartImg from '../../assets/images/dust-page/dustChart.png';
import { useState } from 'react';
import chartOuterCircle from '../../assets/images/dust-page/points.png';
import { useInView } from 'react-intersection-observer';
import percentImg from "../../assets/images/dust-page/percent.png"
import { width } from '@mui/system';
const percentages = [15, 30, 4, 4, 33, 11, 4];
const currentPercentages = [0, 0, 0, 0, 0, 0, 0];

const DistributionChart = () => {
  const [currentPerc, setCurrentPerc] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [playAnim, setPlayAnim] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setCurrentPerc([])
        const interval = setInterval(() => {
          for (let index = 0; index < currentPerc.length; index++) {
            if (currentPerc[index] < percentages[index]) {
//               currentPercentages[index] = currentPercentages[index] + 1;
// setCurrentPerc(currentPercentages)
            setCurrentPerc([...currentPerc, (currentPerc[index] += 1)]);
            }
          }
          //    setCurrentPerc(currentPercentages);
          if (currentPerc[4] === 33) {
            clearInterval(interval);
          }
        }, 30);
      }, 1000);
    }
  }, [playAnim]);

  useEffect(() => {
    if (inView) {
      setPlayAnim(true);
      playTextAppearAnim();
    } else {
      setPlayAnim(false);
      hideText();
      setCurrentPerc([0, 0, 0, 0, 0, 0, 0]);
    }
  }, [inView]);

  const playTextAppearAnim = () =>{
    let num = 1;
    let interval;
    interval = setInterval(() => {
      if(document.getElementById("cp"+ num) === null){
        clearInterval(interval);
      }
      document.getElementById("cp"+ num).classList.add("chart-p-visible");
      num ++;
    }, 500);
  }

  const hideText = () =>{
    for (let index = 1; index < 8; index++) {
      document.getElementById("cp"+ index).classList.remove("chart-p-visible");
    }
  }

  return (
    <div className='chart-parent'>
    <div className="chart-outer-circle desktop-chart" ref={ref}>
        {/* <div className={`chart-texts ${playAnim ? "chart-p-visible":""}`}> */}
        <div>
            <p className='chart-p chart-p-1' id="cp7">Ecosystem<br/> drops</p>
            <p className='chart-p chart-p-2' id="cp1">Reserve</p>
            <p className='chart-p chart-p-3' id="cp2">Advisors</p>
            <p className='chart-p chart-p-4' id="cp3">Grants</p>
            <p className='chart-p chart-p-5' id="cp4">Cardholder<br/>drops</p>
            <p className='chart-p chart-p-6' id="cp5">Team</p>
            <p className='chart-p chart-p-7' id="cp6">Strategic<br/>partners</p>
        </div>
        <img
          className={`points-img ${playAnim ? 'playCircleAnim' : ''}`}
          src={chartOuterCircle}
        />
        <div className="chart-inner-circle">
          <>
            <img src={pieChart} />
            {currentPerc.slice(0,currentPerc.length-1).map((perc, index) => {
              return (
                <p key={index} className={`chart-percentage chart-${index}`}>
                  {perc + "%"}
                </p>
              );
            })}
          </>
        </div>
      </div>
      <div className='mobile-chart'>
        <img src={chartImg}/>
      </div>
    </div>
  );
};
export default DistributionChart;
