import ItemsCarousel from 'react-items-carousel';
import launchpad from '../../assets/images/dust-page/launchpad.png';
import governance from '../../assets/images/dust-page/governance.png';
import fellowship from '../../assets/images/dust-page/fellowship.png';
import service from '../../assets/images/dust-page/service.png';
import merchandise from '../../assets/images/dust-page/merchandise.png';
import { useState } from 'react';
import { useEffect } from 'react';
import CoreCard from './components/CoreCard';
const useCases = [
    {
      image: launchpad,
      title: 'Launchpad',
      text: 'Currently the Galaxis team is working on the release of the Launchpad. The Launchpad is a no code solution to empower creators with the tools necessary to build Web3 communities using dynamic NFTs. This simple and effective platform will guide a creator through the launch process from idea to mint. While many of these features are free, users will have the option to pay preferred pricing for premium features using $DUST.',
    },
    {
      image: fellowship,
      title: 'Fellowship Program',
      text: 'It is essential that Galaxis has a vibrant community of participants who drive engagement, help with decision making, and jump-start projects launching in the Galaxis ecosystem. The Galaxis $DUST Fellowship Program (GDFP) has been created to  incentivize early participants in the Galaxis ecosystem. The GDFP will help support new and exciting projects looking to mint a project within Galaxis. Approved projects will receive a $DUST grant from the Fellowship. The GDFP will be made up of community members called the ‘Crew’.  Initially the Crew will be capped at 200 participants, where each member of the GDFP Crew will be required to hold an Ether Card with $DUST on it in order to participate.',
    },

    {
      image: merchandise,
      title: 'Merchandise',
      text: 'The Galaxis team is currently building a $DUST only store to be able to purchase Galaxis and partner project swag. This function will be integrated into the Galaxis website for seamless $DUST transactions. ',
    }
  ];
const UseCases = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeItemIndexForYourCards, setActiveItemIndexForYourCards] = useState(0);
    const [firstAndLastGutter,setFirstAndLastGutter] = useState(false);
    const [numberOfCards, setNumberOfCards] = useState(3);

    useEffect(() => {
        if (window.innerWidth < 1024) {
          setNumberOfCards(3);
        }
        if (window.innerWidth < 750) {
          setNumberOfCards(2);
        }
        if (window.innerWidth < 550) {
          setNumberOfCards(1);
          setFirstAndLastGutter(true)
        }
      }, []);
    //   useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setActiveItemIndexForYourCards(activeItemIndexForYourCards + 1);
    //     }, 4000);
    
    //     return () => {
    //       clearTimeout(timeout);
    //     };
    //   }, [activeItemIndexForYourCards]);


    return <>   <ItemsCarousel
    requestToChangeActive={setActiveItemIndexForYourCards}
    activeItemIndex={activeItemIndexForYourCards}
    numberOfCards={numberOfCards}
    infiniteLoop={true}
    firstAndLastGutter={firstAndLastGutter}
    gutter={20}
    // leftChevron={<CustomLeft />}
    // rightChevron={<CustomRight />}
  >
    {useCases.map(
      ({ image, title, text, highlighted, index }) => (
        // <Grid item xs={12} sm={6} md={4} key={index}>
        <CoreCard
          key={title}
          image={image}
          title={title}
          text={text}
          highlighted={highlighted}
        />
        // </Grid>
      )
    )}
    {/* 
{useCases.map(({ image, title, text, highlighted, index }) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <CoreCard
        key={title}
        image={image}
        title={title}
        text={text}
        highlighted={highlighted}
      />
    </Grid>
  ))} */}
  </ItemsCarousel>
  </>}
export default UseCases;