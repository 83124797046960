import DustPage from "../views/DustPage/DustPage"
import DustPageTwo from "../views/DustPage/DustPageTwo";
const routes = [
{
    path: '/',
    component: <DustPageTwo />,
    exact: true
},
];

export default routes;