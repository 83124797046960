import "./DustDistribution.css"
import { Box, Typography, Grid } from '@mui/material';
import dustChart from "../../assets/images/dust-page/dustChart.png";
import DistributionChart from "./DistributionChart";
const BP1 = '@media (max-width: 619px)';
const sx = {
    titleHolder: {
      display: 'flex',
      maxWidth: '425px',
      margin: '0 auto',
      alignItems: 'center',
    },
    title: {
      maxWidth: '550px',
      mx: 'auto',
      textAlign: 'center',
      mt: '8px',
      [BP1]: {
        maxWidth: '300px',
      },
    },
    description: {
      mx: 'auto',
      maxWidth: '638px',
      mt: '20px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textAlign:"right"
    },

  };
const DustDistribution = () =>{
    return <div className="dust-distribution">
        <div className="one-third">
        <Box sx={sx.titleHolder}>
              <Typography
                id="upcoming"
                variant="bannerTitle"
                textAlign="center"
                sx={sx.title}
                style={{
                  maxWidth: '100%',
                  position: 'relative',
                  justifyContent: 'space-between',
                  // marginTop: '30px',
                }}
              >
                $DUST<br/> Distribution
              </Typography>
             
            </Box>
            <Typography variant="bannerText" sx={sx.description}>
              We have also chosen to recognize the contribution of investors and team members with a distribution of $DUST.  These community members are working to help bring the Galaxis vision to life.  
 
<br/><br/>The proposed distribution of the $DUST utility token looks like this
              </Typography>

        </div>
        <div className="two-third">
          {/* <img src={dustChart}/> */}
          <DistributionChart/>
          </div>
    </div>
}
export default DustDistribution;