import './DustTable.css';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('First drop', 159, 6.0, 24),
  createData('Second drop', 237, 9.0, 37),
  createData('Trickling', 262, 16.0, 24),
];

const sx = {
  root: {
    borderRadius: '20px',
    margin: '20px 0',
    '& th': {
      color: '#000',
      textAlign: 'center',
      borderBottom: '1px solid rgb(165 165 165)',
    },
    '& td': {
      borderBottom: '1px solid rgb(165 165 165)',
    },
  },
};
const DustTable = () => {
  return (
    <>
      <table className="bordered">
        <thead>
          <tr>
            <th>
              <label></label>
            </th>
            <th>
              <label>OG</label>
            </th>
            <th>
              <label>Alpha</label>
            </th>
            <th>
              <label>Founder</label>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <label className='medium-poppins'>Drop 1</label>
            </td>
            <td>
              <label>100,000</label>
            </td>
            <td>
              <label>10,000</label>
            </td>
            <td>
              <label>1,000</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className='medium-poppins'>Drop 2</label>
            </td>
            <td>
              <label>21,100</label>
            </td>
            <td>
              <label>2,110</label>
            </td>
            <td>
              <label>211</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className='medium-poppins'>Annual dusting</label>
            </td>
            <td>
              <label>233,571</label>
            </td>
            <td>
              <label>45,714</label>
            </td>
            <td>
              <label>7,144</label>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DustTable;
