import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import "../DustPage/Home.css"
import "../DustPage/DustPoolCard.css"
import Footer from './Footer';
import dustIconWithShadow from '../../assets/images/dust-page/dustWithShadow.png';
import twitter from '../../assets/images/social/twitter.svg';
import discord from '../../assets/images/social/discord.svg';
import tokenInfo from '../../assets/images/dust-page/tokenInfo.png';
import SectionDividers from './components/SectionDividers';
import './DustPage.css';
import ethLogo from '../../assets/images/dust-page/eth.png';
import polygon from '../../assets/images/dust-page/polygon.png';
import CardDustReward from './CardDustReward';
import Alpha from '../../assets/images/dust-page/alpha.jpeg';
import OG from '../../assets/images/dust-page/og.jpeg';
import Founder from '../../assets/images/dust-page/founder.jpg';
import DustingComponent from './DustingComponent';
import DustDistribution from './DustDistribution';
import CoreCard from './components/CoreCard';
import launchpad from '../../assets/images/dust-page/launchpad.png';
import governance from '../../assets/images/dust-page/governance.png';
import fellowship from '../../assets/images/dust-page/fellowship.png';
import service from '../../assets/images/dust-page/service.png';
import merchandise from '../../assets/images/dust-page/merchandise.png';
import cselJobb from '../../assets/images/dust-page/cselJobb.png';
import cselBal from '../../assets/images/dust-page/cselBal.png';
import ItemsCarousel from 'react-items-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import CustomLeft from './components/CustomLeft';
import CustomRight from './components/CustomRight';
import amm from '../../assets/images/dust-page/amm.png';
import mike from '../../assets/images/dust-page/mike.jpg';
import god from '../../assets/images/dust-page/god.jpg';
import freedom from '../../assets/images/dust-page/freedom.jpg';
import grd from '../../assets/images/dust-page/GRD_Vault.jpg';
import alpha1 from '../../assets/images/dust-page/alpha1.png';
import alpha2 from '../../assets/images/dust-page/alpha2.jpeg';
import alpha3 from '../../assets/images/dust-page/alpha3.jpeg';
import og1 from '../../assets/images/dust-page/og1.jpeg';
import founder1 from '../../assets/images/dust-page/founder1.jpg';
import founder2 from '../../assets/images/dust-page/founder2.jpg';
import founder3 from '../../assets/images/dust-page/founder3.jpg';
import mmmask from '../../assets/images/dust-page/MMASK.png';
const dustRewards = [
  {
    img: OG,
    tier: 'OG',
    dust: 'D 100.000',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/41"
  },
  {
    img: Alpha,
    tier: 'Alpha',
    dust: 'D 10.000',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/700"
  },
  {
    img: Founder,
    tier: 'Founder',
    dust: 'D 1.000',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/8585"
  },
];
const dustRewardsSecond = [
  {
    img: OG,
    tier: 'OG',
    dust: 'D 21.100',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/41"
  },
  {
    img: Alpha,
    tier: 'Alpha',
    dust: 'D 2110',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/700"
  },
  {
    img: Founder,
    tier: 'Founder',
    dust: 'D 211',
    url:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/8585"
  },
];
const dustingObj = [
  {
    img: OG,
    poolTitle: 'ANNUAL DUSTING IN 2022 PER OG',
    poolPrice: 'D 233.571',
  },
  {
    img: Alpha,
    poolTitle: 'ANNUAL DUSTING IN 2022 PER ALPHA',
    poolPrice: 'D 45.714',
  },
  {
    img: Founder,
    poolTitle: 'ANNUAL DUSTING IN 2022 PER FOUNDER',
    poolPrice: 'D 7.144',
  },
];
const dustPools = [
  {
    img: mike,
    poolTitle: 'Mike Tyson Vault',
    poolPrice: '25,000 D',
  },
  {
    img: grd,
    poolTitle: 'Girls, Robots, Dragons',
    poolPrice: '6,000 D',
  },
  {
    img: freedom,
    poolTitle: 'NFTs For Freedom',
    poolPrice: '1,200 D',
  },
  {
    img: god,
    poolTitle: 'GOD Panels',
    poolPrice: 'TBA',
  },
];
const getYourCards = [
  {
    img: alpha1,
    poolTitle: 'Alpha 863',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/863"
  },
  {
    img: alpha2,
    poolTitle: 'Alpha 817',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/817"
  },
  {
    img: alpha3,
    poolTitle: 'Alpha 690',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/690"
  },
  {
    img: og1,
    poolTitle: 'OG 48',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/48"
  },
  {
    img: founder1,
    poolTitle: 'Founder 8611',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/8611"
  },
  {
    img: founder2,
    poolTitle: 'Founder 6383',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/6383"
  },
  {
    img: founder3,
    poolTitle: 'Founder 5945',
    clickableWindowUrl:"https://opensea.io/assets/ethereum/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/5945"
  },
];
const useCases = [
  {
    image: launchpad,
    title: 'Launchpad',
    text: 'Currently our team is working on the release of the Galaxis Launchpad.  The Launchpad is our no code solution to empower creators with the tools necessary to build Web3 communities using dynamic NFTs.  This simple and effective platform will guide a creator through the launch process from idea to mint.  While many of these features are free, users will have the option to pay preferred pricing for premium features using $DUST.',
  },
  // {
  //   image: governance,
  //   title: 'Governance',
  //   text: 'There will be a number of opportunities for service providers to participate in the Galaxis ecosystem.  Whether it’s services like providing storage, doing code audits or creating new functionalities, we expect a diverse set of service providers to be involved in the community. In order to interact within the ecosystem service providers will need to stake $DUST as a guarantee for services provided and to begin building a ‘reputation score’. ',
  // },
  {
    image: fellowship,
    title: 'Fellowship Program',
    text: 'It is essential that we have a vibrant community of participants who drive engagement, help with decision making, and jump-start projects launching in the Galaxis. To incentivize early participants in the Galaxis ecosystem we have created the Galaxis $DUST Fellowship Program (GDFP).  The GDFP will help support new and exciting projects looking to mint a project with Galaxis. Approved projects will receive a $DUST grant from the Fellowship. The GDFP will be made up of community members that we will call the ‘Crew’. Galaxis will be granting 10 million $DUST to the program to be deployed by the Crew.  We plan on initially capping the GDFP at 200 participants, where each member of the GDFP Crew will be required to hold an Ether Card with 10,000 $DUST on it in order to participate. ',
  },
  // {
  //   image: service,
  //   title: 'Service Providers',
  //   text: 'There will be a number of opportunities for service providers to participate in the Galaxis ecosystem.  Whether it’s services like providing storage, doing code audits or creating new functionalities, we expect a diverse set of service providers to be involved in the community. In order to interact within the ecosystem service providers will need to stake $DUST as a guarantee for services provided and to begin building a ‘reputation score’. ',
  // },
  {
    image: merchandise,
    title: 'Merchandise',
    text: 'Our team is currently building a $DUST only store to be able to purchase Galaxis and partner project swag.  This function will be integrated into the Galaxis website for seamless $DUST transactions. ',
  },
];

const BP1 = '@media (max-width: 619px)';
const BP2 = '@media (max-width: 1348px)';
const BP4 = '@media (max-width: 1350px)';
const BP5 = '@media (max-width: 871px)';
const BP6 = '@media (max-width: 471px)';

const sx = {
  root: {
    position: 'relative',
    width: '100%',
    pt: '50px',
    pb: '115px',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#FAF9F5',
    px: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleHolder: {
    display: 'flex',
    maxWidth: '425px',
    margin: '0 auto',
    alignItems: 'center',
  },
  socialContainer: {
    maxHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    mb: '10px',
    gap: '10px',
    [BP5]: {
      justifyContent: 'center',
    },
  },
  cardContainer: {
    width: '100%',
    maxWidth: '1140px',
    mt: '50px',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '14px 12px',
    [BP2]: {
      justifyContent: 'center',
    },
  },
  content: {
    maxWidth: '1140px',
    position: 'relative',
    width: '100%',
  },
  title: {
    maxWidth: '550px',
    mx: 'auto',
    textAlign: 'center',
    mt: '8px',
    [BP1]: {
      maxWidth: '300px',
    },
  },
  description: {
    mx: 'auto',
    textAlign: 'center',
    maxWidth: '638px',
    mt: '20px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  viewbtn: {
    mt: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};
const getShrinkStyle = (bp) => ({ [bp]: { justifyContent: 'center' } });

const VideoPage = () => {
  const handleClick = (param) => {};
  const openNewPage = (url) => {
    window.open(url);
  };
  const LittleCard = ({ imgSrc, title, description }) => {
    return (
      <div className="little-card-container">
        {/* <img src={imgSrc} /> */}
        <div
          className="little-icon"
          style={{ backgroundImage: `url(${imgSrc})` }}
        ></div>
        <Typography variant="bannerTitle" textAlign="center" sx={sx.title}>
          {title}
        </Typography>
        <Typography
          variant="bannerText"
          onClick={() => window.open(description)}
          sx={sx.description}
        >
          {description}
        </Typography>
      </div>
    );
  };
  const AddTokenToMetamask = async (address) => {
    const tokenAddress = address;
    const tokenSymbol = 'DUST';
    const tokenDecimals = 18;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemIndexForYourCards, setActiveItemIndexForYourCards] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(4);
  useEffect(() => {
    if (window.innerWidth < 1024) {
      setNumberOfCards(3);
    }
    if (window.innerWidth < 750) {
      setNumberOfCards(2);
    }
    if (window.innerWidth < 550) {
      setNumberOfCards(1);
    }
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div className="dust-page">
        <Box sx={sx.root}>
          <Box sx={sx.content}>
            <div className="border" style={{ position: 'relative' }}>
              <div className="dust-intro-section">
                <Box
                  sx={sx.titleHolder}
                  style={{ maxWidth: '460px', flexWrap: 'wrap' }}
                >
                  <div className="intro-img">
                    <img
                      src={dustIconWithShadow}
                      style={{ maxWidth: '170px' }}
                    />
                  </div>
                  <div className="intro-title">
                    <Typography
                      id="upcoming"
                      variant="bannerTitle"
                      textAlign="center"
                      sx={sx.title}
                      style={{
                        maxWidth: '100%',
                        position: 'relative',
                        textAlign: 'left',
                        justifyContent: 'space-between',
                      }}
                    >
                      $DUST
                      <br /> Utilty Token
                      <Box sx={sx.socialContainer}>
                        <a
                          href="https://discord.com/invite/mBwauRSJNW"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box>
                            <img
                              src={discord}
                              style={{ width: '28px' }}
                              alt="discord"
                            />
                          </Box>
                        </a>
                        <a
                          href="https://twitter.com/galaxisxyz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box>
                            <img
                              src={twitter}
                              style={{ width: '28px' }}
                              alt="twitter"
                            />
                          </Box>
                        </a>

                        {/* <Box onClick= {()=> setModalIsOpen(true)} style={{cursor: 'pointer'}}>
              <img src={email} style={{ width: '100%' }} alt="not found" />
            </Box> */}
                      </Box>
                    </Typography>
                  </div>
                </Box>
                <Box style={{ width: '100%', textAlign: 'center' }}>
                  <img
                    src={tokenInfo}
                    style={{ maxHeight: '100px', margin: '0 auto' }}
                  />
                </Box>
                <Typography variant="bannerText" sx={sx.description}>
                Galaxis is a decentralized network of vibrant communities built leveraging our infrastructure and engagement tools. In order for these communities to transact, develop and interact within Galaxis we created the $DUST utility token.<br/>
$DUST is the ERC-777 token on the Ethereum blockchain created for the Galaxis ecosystem.<br/>
The supply of $DUST is capped at a maximum of 5 billion tokens which will be distributed over a period of a minimum of 10 years.  As of 2022, the issuance and claiming of $DUST is on the Polygon network.
                </Typography>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  {/* <Box>
                    <button
                      className="dust-pool-btn"
                      onClick={() =>
                        window.open(
                          'https://opensea.io/collection/ether-cards-founder'
                        )
                      }
                      style={{ margin: '5px' }}
                    >
                      Buy Ethercard
                    </button>
                    <button
                      className="dust-pool-btn"
                      onClick={() =>
                        window.open(
                          'https://opensea.io/collection/girls-robots-dragons-official'
                        )
                      }
                      style={{ margin: '5px' }}
                    >
                      Buy GRD nft
                    </button>
                  </Box> */}
                </div>
              </div>
              <SectionDividers />
              <div className="tokenAddressesDiv">
                <img className="jobbCsel" src={cselJobb} />
                <img className="balCsel" src={cselBal} />
                <Box sx={sx.titleHolder}>
                  <Typography
                    id="upcoming"
                    variant="bannerTitle"
                    textAlign="center"
                    sx={sx.title}
                    style={{
                      maxWidth: '100%',
                      position: 'relative',
                      justifyContent: 'space-between',
                      // marginTop: '30px',
                      padding: '0 23px',
                    }}
                  >
                    $DUST Contract Address
                  </Typography>
                </Box>
                <div className="little-card-outer">
                  <div className="add-to-metamask-c">
                    <div
                      className="add-to-metamask-d"
                      onClick={() =>
                        AddTokenToMetamask(
                          '0xe2E109f1b4eaA8915655fE8fDEfC112a34ACc5F0'
                        )
                      }
                    >
                      <img src={amm} />
                    </div>
                  </div>
                  
                  <LittleCard
                    imgSrc={ethLogo}
                    title="Etherum Mainnet Contract"
                    description="https://etherscan.io/token/0xe2e109f1b4eaa8915655fe8fdefc112a34acc5f0"
                  />
                  <div className="add-to-metamask-c-m">
                    <div
                      className="add-to-metamask-d-m dust-pool-btn"
                      onClick={() =>
                        AddTokenToMetamask(
                          '0xe2E109f1b4eaA8915655fE8fDEfC112a34ACc5F0'
                        )
                      }
                    >
                      <img src={mmmask} style={{width: "26px",marginRight: "10px"}}/>
                      Add to metamask
                    </div>
                  </div>
                  <LittleCard
                    imgSrc={polygon}
                    title="Polygon Network Contract"
                    description="https://polygonscan.com/token/0x167ef3603c0b1d52be9a96e3af01aa33097a6ce9"
                  />
                  <div className="add-to-metamask-c-m">
                    <div
                      className="add-to-metamask-d-m dust-pool-btn"
                      onClick={() =>
                        AddTokenToMetamask(
                          '0x167ef3603c0b1d52be9a96e3af01aa33097a6ce9'
                        )
                      }
                    >
                      <img src={mmmask} style={{width: "26px",marginRight: "10px"}}/>
                      Add to metamask
                    </div>
                  </div>
                  <div className="add-to-metamask-c">
                    <div
                      className="add-to-metamask-d"
                      onClick={() =>
                        AddTokenToMetamask(
                          '0x167ef3603c0b1d52BE9a96E3Af01aa33097a6CE9'
                        )
                      }
                    >
                      <img src={amm} style={{ transform: 'rotateZ(180deg)' }} />
                    </div>
                  </div>
                </div>

                {/* <a href='https://www.coingecko.com/en/coins/dust' target="blank">Live price</a> */}
              </div>
              <SectionDividers />
              <div style={{ position: 'relative' }}>
                <DustDistribution />
                {/* <img
                  className="balCsel"
                  src={cselJobb}
                  style={{
                    position: 'absolute',
                    top: '-34%',
                    left: '76%',
                    maxWidth: 'unset',
                  }}
                /> */}
              </div>
              <SectionDividers />
              <Box sx={sx.titleHolder}>
                <Typography
                  variant="bannerTitle"
                  textAlign="center"
                  sx={sx.title}
                  style={{
                    maxWidth: '100%',
                    position: 'relative',
                    justifyContent: 'space-between',
                    // marginTop: '30px',
                    padding: '0 23px',
                  }}
                >
                 $DUST Use Cases
                </Typography>
              </Box>
              <Typography variant="bannerText" sx={sx.description}><b>$DUST Rewards</b></Typography>
              <Typography variant="bannerText" sx={sx.description}>
              In Q3 2021, 27,000,000 $DUST was distributed to Ether Card holders in the following amounts:
              </Typography>
             
              <div className="card-reward-container">
                {dustRewards.map((dustReward) => {
                  return (
                    <CardDustReward
                      img={dustReward.img}
                      tier={dustReward.tier}
                      dust={dustReward.dust}
                      url={dustReward.url}
                    />
                  );
                })}
              </div>
              
              <Typography
                variant="bannerText"
                sx={sx.description}
                style={{ padding: '20px 0' }}
              >
               See the $DUST allocation contract here: <br/>
                <a
                  style={{ color: '#FF692B' }}
                  href="https://etherscan.io/address/0xCF01E438E6bC82653a65273f215Ae5e5D19B1B33"
                  target="blank"
                >
                  https://etherscan.io/address/0xCF01E438E6bC82653a65273f215Ae5e5D19B1B33
                </a>
              </Typography>
             
              {/* <Typography variant="bannerText" sx={sx.description}>
                Later on a SECOND DUSTdrop was made, where 5,697,000 DUST tokens
                were airdropped onto Ether Cards *in the following amounts:
                <br />
                <br />
                <b>
                  {' '}
                  WE HAVE TO SHOW SOMETHING HERE; TABLE OR CARDS WITH THE DROP
                  AMOUNT!!!!
                </b>
              </Typography> */}
              <Typography variant="bannerText" sx={sx.description}>
              In Q4 2021, a further 5,697,000 $DUST was distributed to Ether Card holders in the following amounts:</Typography>
              <div className="card-reward-container">
                {dustRewards.map((dustReward) => {
                  return (
                    <CardDustReward
                      img={dustReward.img}
                      tier={dustReward.tier}
                      dust={dustReward.dust}
                      url={dustReward.url}
                    />
                  );
                })}
              </div>
              <Typography variant="bannerText" sx={sx.description}>

              See the $DUST allocation contract here: <br/>
                <a
                  href="https://etherscan.io/address/0xcC45A0765E4FCfe174ae6BA7C4F20E1fA3beDb4d"
                  style={{ color: '#FF692B' }}
                  target="blank"
                >
                  https://etherscan.io/address/0xcC45A0765E4FCfe174ae6BA7C4F20E1fA3beDb4d
                </a>
              </Typography>
              <Typography variant="bannerText" sx={sx.description}>
              In Q1 2022, we chose to continue rewarding Ether Card holders for their support through a daily trickle of $DUST onto their cards. We created a dusting schedule where a holder would accumulate $DUST to recognize their membership in the Ether Card community.
              </Typography>
              <div className="card-reward-container">
                {dustingObj.map((dustingOb) => {
                  return (
                    <DustingComponent
                      imgUrl={dustingOb.img}
                      poolTitle={dustingOb.poolTitle}
                      poolPrice={dustingOb.poolPrice}
                      dividedToThree={true}
                    />
                  );
                })}
              </div>

              <Typography
                variant="bannerText"
                sx={sx.description}
                style={{ marginTop: '30px' }}
              >
                Each card collects $DUST based on their card tier. The OG receives the highest base rate of $DUST followed by the Alpha cards and the Founder cards. <br/> See the $DUST trickling contract here:<br/>
                <a
                  href="https://polygonscan.com/address/0xe41b7c9b08ecc127670ea0dd2ed122277880612c"
                  style={{ color: '#FF692B' }}
                  target="blank"
                >
                  https://polygonscan.com/address/0xe41b7c9b08ecc127670ea0dd2ed122277880612c
                </a>
              </Typography>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Box>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '5px' }}
                    onClick={() =>
                      window.open(
                        'https://opensea.io/collection/ether-cards-founder'
                      )
                    }
                  >
                    Ether cards
                  </button>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '5px' }}
                    onClick={() =>
                      window.open('https://explorer.ether.cards/#/wallet')
                    }
                  >
                    check your $DUST
                  </button>
                </Box>
              </div>
              <SectionDividers />
              <Box sx={sx.titleHolder}>
                <Typography
                  variant="bannerTitle"
                  textAlign="center"
                  sx={sx.title}
                  style={{
                    maxWidth: '100%',
                    position: 'relative',
                    justifyContent: 'space-between',
                    // marginTop: '30px',
                    padding: '0 23px',
                  }}
                >
                  Nft Vaults
                </Typography>
              </Box>
              <Typography variant="bannerText" sx={sx.description}>
              The NFT Vault (previously known as the $DUST pool) is a quasi NFT store where community members can use their $DUST to purchase NFTs. The initial NFT Vault contained bluechip projects such as Cryptopunks and Meebits and also included NFTs from Galaxis partner projects.  Some Galaxis NFT Vault highlights: 27 CryptoPunks, 30 Meebits, 50 Alpha Ether Cards, 200 Founder Ether Cards, 200 GRDs, and 25 Mike Tyson NFTs.
                <br />
                <br />
                The NFT Vault has proven extremely popular with numerous NFT collections now sold out. The goal for the NFT Vault is that any project launched using Galaxis will designate a number of NFTs to the NFT Vault for purchase by the community. Our goal is to automate this process so that the NFT Vault will be populated on a continuous basis allowing our community a method by which to spend their $DUST rewards while supporting their favourite NFT projects.

                <br />
                <br />
                The NFT Vault contract can be found here:{' '}
                <a
                  style={{ color: '#FF692B' }}
                  href="https://etherscan.io/address/0xc8725085541967993253C6EA460DD96dafFABee5"
                  target="blank"
                >
                  https://etherscan.io/address/0xc8725085541967993253C6EA460DD96dafFABee5
                </a>
                <br />
                <br />
                See the most recent{' '}
                <span style={{ color: '#FC7705' }}>Vaults </span>below:
              </Typography>
              <div className="dust-pools-container">
                {dustPools.map((dustingOb) => {
                  return (
                    <DustingComponent
                      imgUrl={dustingOb.img}
                      poolTitle={dustingOb.poolTitle}
                      poolPrice={dustingOb.poolPrice}
                      dividedToThree={false}
                      clickableWindowUrl="https://galaxis.xyz/#/nft-vaults"
                    />
                  );
                })}
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Box>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '5px' }}
                    onClick={()=>window.open("https://galaxis.xyz/#/nft-vaults")}
                  >
                    See all vaults
                  </button>
                </Box>
              </div>
              <SectionDividers />
              <div className="dust-proofing">
                <div className="d-p-div a-right" style={{ textAlign: 'left' }}>
                  <img
                    src={dustIconWithShadow}
                    style={{ transform: 'scale(1.1)' }}
                  />
                </div>
                <div
                  className="d-p-div a-left"
                  style={{ position: 'relative' }}
                >
                  <img
                    className="balCsel"
                    src={cselBal}
                    style={{
                      position: 'absolute',
                      top: '0',
                      top: '-26%',
                      right: '173%',
                      maxWidth: 'unset',
                    }}
                  />
                  <Typography
                    variant="bannerTitle"
                    sx={sx.title}
                    style={{
                      maxWidth: '100%',
                      position: 'relative',
                    }}
                  >
                    $DUSTProofing
                  </Typography>
                  <Typography variant="bannerText" sx={sx.description}>
                  An allocation of $DUST has been set aside to help creators that need a little extra support in the launch of projects that Galaxis feels will benefit the ecosystem. We call this mechanism ‘$DUSTproofing'. $DUSTproofing allows a creator to add $DUST to the NFTs they mint in order to incentivize community participation.
                    <br />
                    <br />
                    The first recipient of $DUSTproofing was the Girls Robot Dragons project. By using $DUSTproofing the first 3000 cards purchased will receive an allocation of $DUST over a period of a year.  $DUST will start trickling on the cards upon minting and will continue to do so until the holder receives their allocation.
                  </Typography>
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Box>
                      <button
                        className="dust-pool-btn"
                        onClick={() => openNewPage('https://grd.fan')}
                        style={{ margin: '5px' }}
                      >
                        Visit GRD.FAN
                      </button>
                    </Box>
                  </div>
                </div>
              </div>
              <SectionDividers />
              <Box sx={sx.titleHolder}>
                <Typography
                  variant="bannerTitle"
                  textAlign="center"
                  sx={sx.title}
                  style={{
                    maxWidth: '100%',
                    position: 'relative',
                    justifyContent: 'space-between',
                    // marginTop: '30px',
                    padding: '0 23px',
                  }}
                >
                What's Next For $DUST?
                </Typography>
              </Box>
              <Typography variant="bannerText" sx={sx.description}>
              Next steps involve integrating $DUST payment into the Galaxis Launchpad, the Galaxis Dust Fellowship Program, and the Galaxis Store.
              </Typography>
              <div className="dust-use-cases">
                <Box sx={{ ...sx.cardContainer, ...getShrinkStyle(BP4) }}>
                  {/* <Grid
                container
                spacing={1.2}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                {useCases.map(({ image, title, text, highlighted, index }) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <CoreCard
                      key={title}
                      image={image}
                      title={title}
                      text={text}
                      highlighted={highlighted}
                    />
                  </Grid>
                ))}
              </Grid> */}
                </Box>
                <div className='carousel-divv'>
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndex}
                  activeItemIndex={activeItemIndex}
                  numberOfCards={numberOfCards}
                  gutter={20}
                  leftChevron={<CustomLeft />}
                  rightChevron={<CustomRight />}
                >
                  {useCases.map(
                    ({ image, title, text, highlighted, index }) => (
                      // <Grid item xs={12} sm={6} md={4} key={index}>
                      <CoreCard
                        key={title}
                        image={image}
                        title={title}
                        text={text}
                        highlighted={highlighted}
                      />
                      // </Grid>
                    )
                  )}
                  {/* 
              {useCases.map(({ image, title, text, highlighted, index }) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <CoreCard
                      key={title}
                      image={image}
                      title={title}
                      text={text}
                      highlighted={highlighted}
                    />
                  </Grid>
                ))} */}
                </ItemsCarousel>
                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Box>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '15px' }}
                    onClick={() =>
                      (window.location = 'mailto:krisztin@galaxis.xyz')
                    }
                  >
                    Contact us
                  </button>
                </Box>
              </div>
              <SectionDividers />
              <Box sx={sx.titleHolder}>
                <Typography
                  variant="bannerTitle"
                  textAlign="center"
                  sx={sx.title}
                  style={{
                    maxWidth: '100%',
                    position: 'relative',
                    justifyContent: 'space-between',
                    // marginTop: '30px',
                    padding: '0 23px',
                  }}
                >
                  How To Get $DUST
                </Typography>
              </Box>
              <Typography variant="bannerText" sx={sx.description}>
                If you would like to join us on this journey and are not yet a
                community member, the easiest way to obtain $DUST is by
                purchasing an Ether Card or one of the $DUSTproofed GRD NFTs
                (while supplies last). Keep these NFTs in your wallet and watch
                the $DUST accumulate while we grow the Galaxis community
                together.
              </Typography>
              <div className="get-yours-c">
              <div className='carousel-divv'>
              <ItemsCarousel
                  requestToChangeActive={setActiveItemIndexForYourCards}
                  activeItemIndex={activeItemIndexForYourCards}
                  numberOfCards={numberOfCards}
                  gutter={20}
                  leftChevron={<CustomLeft />}
                  rightChevron={<CustomRight />}
                >
                {getYourCards.map((dustingOb) => {
                  return (
                    <DustingComponent
                      imgUrl={dustingOb.img}
                      poolTitle={dustingOb.poolTitle}
                      poolPrice={dustingOb.poolPrice}
                      dividedToThree={false}
                      clickableWindowUrl={dustingOb.clickableWindowUrl}
                    />
                  );
                })}
                </ItemsCarousel>
                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Box>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '5px' }}
                    onClick={() =>
                      window.open(
                        'https://opensea.io/collection/ether-cards-founder'
                      )
                    }
                  >
                    ETHER CARDS
                  </button>
                  <button
                    className="dust-pool-btn"
                    style={{ margin: '5px' }}
                    onClick={() =>
                      window.open(
                        'https://opensea.io/collection/girls-robots-dragons-official'
                      )
                    }
                  >
                    VISIT GRD.FAN
                  </button>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default VideoPage;
