import { Box, Typography } from '@mui/material';
const DustingComponent = ({imgUrl,poolTitle,poolPrice,dividedToThree,clickableURL,clickableWindowUrl}) =>{
    const sx = {
        content: {
          maxWidth: '380px',
          margin: 'auto'
        },
        dotList: {
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          zIndex: '3',
          position: 'absolute',
          justifyContent: 'center',
        },
        contentLeft: {
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderRadius: '12px 12px',
          cursor:'pointer'
        },
        titleText: {
          fontFamily: 'poppins-semibold',
          fontSize: '18px',
          lineHeight: '20px',
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": '2',
          "-webkit-box-orient": "vertical"
        
      
        },
        description: {
          fontFamily: 'poppins',
          fontSize: '14px',
          mt: '5px',
          mb: '10px',
          lineHeight:"15px"
        },
        imageHolder: {
          width: '100px',
        },
        itemHolder: {
          maxWidth: '60%',
        },
        image: {
      
        }
      };

      const handleClick = () =>{
        if(clickableWindowUrl){
          window.open(clickableWindowUrl)
        }
      }
   return <div className='dusting-component' style={{flex: dividedToThree ? "33%" : "25%"}} onClick={()=>handleClick()}>
   <Box sx={sx.content} style={{boxShadow: "0px 7px 12px -4px rgba(0,0,0,0.4)", borderRadius:"12px", overflow:"hidden"}}>
              <Box sx={sx.contentLeft} className="previousBox">
                <Box sx={sx.imageHolder}>
                  <img
                    src={imgUrl}
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      // borderRadius: '12px 0px 0px 12px',
                      display:"block"
                    }}
                  />{' '}
                </Box>
                <Box sx={sx.itemHolder}>
                <Typography sx={sx.description}
                  >
                    {poolTitle}
                  </Typography>
                  <Typography  variant="minuscule"
                    sx={sx.titleText}
                    className="prevtitleText">{poolPrice}</Typography>
                </Box>
              </Box>
            </Box>
            </div>
}
export default DustingComponent;