import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LazyLoad from "react-lazyload";

const BP2 = '@media (max-width: 1136px)';
const BP3 = '@media (max-width: 899px)';
const BP4 = '@media (max-width: 772px)';
const BP5 = '@media (max-width: 599px)';
const BP6 = '@media (max-width: 419px)';

const sx = {
  border: {
    padding: '2px',
    height: '100%',
    borderRadius: '17px',
    boxShadow: "0px 0px 9px 5px rgb(0 0 0 / 4%)"
  },
  root: {
    width: '100%',
    pl: '20.3px',
    pr: '13.7px',
    py: '27px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '38px',
    },
    backgroundColor: '#FFFFFF',
    height: '100%',
    borderRadius: '15px',
  },
  title: {
    pr: '6.6px',
    fontFamily: 'poppins-medium',
    fontSize: '22px',
    lineHeight: '22px',
    color: '#030000',
    mt: '12px',
    textAlign: 'center',
    height: '55px',
    textAlign: 'center',
    [BP2]: {
      minHeight: '75px',
    },
    [BP3]: {
      minHeight: '55px',
    },
    [BP4]: {
      minHeight: '75px',
    },
    [BP5]: {
      height: 'unset',
      minHeight: 'unset',
    },
  },
  text: {
    width: '100%',
    fontFamily: 'bau',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#030000',
    height: '110px',
    overflowY: 'scroll',
    pr: '13.6px',
    [BP5]: {
      height: 'unset',
      textAlign: 'center',
      mt: '27px',
      pr: '6.6px',
    },
  },
};

const CoreCard = ({ image, title, text }) => (
  <Box sx={sx.border}>
    <Box sx={sx.root}>
    <Box style={{ paddingRight: '6.6px',paddingBottom: "10px", }}>
      <LazyLoad>
        <img src={image} alt={title} /></LazyLoad>
      </Box>
      <Typography sx={sx.title}>{title}</Typography>
      <Typography sx={sx.text}>{text}</Typography>
    </Box>
  </Box>
);

CoreCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CoreCard;
