import { Box } from "@mui/material";
import leftTrick from '../../../assets/images/dust-page/leftTrick.svg';
import rightTrick from '../../../assets/images/dust-page/rightTrick.svg';

const SectionDividers = () => (
	<div className="section-divider">
		<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
		<img src={leftTrick} style={{ maxHeight: '50px' }} />
		<img src={rightTrick} style={{ maxHeight: '50px' }} />
		</Box>
	</div>
  );

export default SectionDividers;